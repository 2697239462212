import CountUp from 'countup.js';
import VisSenseFactory from 'vissense'

// Start counter when fact is visible
// requires window when initialised from CommonJS with WebPack,
// see https://github.com/vissense/vissense/issues/35
const VisSense = VisSenseFactory(window);
const facts = document.querySelectorAll('.Fact-value');
facts.forEach(function (fact) {
    const countTo = fact.textContent.replace(/[^0-9.]/g, "");
    const counter = new CountUp(fact, 0, countTo, 0, 2, {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
    });


    if (!counter.error) {
        VisSense(fact, { fullyvisible: 0.75 }).monitor({
            fullyvisible: function() {
                counter.start();
            },
        }).start();

    } else {
        counter.error(counter.error);
    }
});


