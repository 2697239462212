import Utils from './utils';

var container = document.getElementById("container");
var containerInner = document.getElementById("container-inner");

function preventBouncing(event) {
    if (container.scrollTop < 1 || container.scrollTop > containerInner.clientHeight - container.clientHeight) {
        event.preventDefault();
    }
}

function raf() {
    window.requestAnimationFrame(raf);

    if (container.scrollTop < 1) {
        container.scrollTop = 1;
    }
    else if (container.scrollTop > containerInner.clientHeight - container.clientHeight) {
        container.scrollTop = containerInner.clientHeight - container.clientHeight;
    }
}

if (Utils.isSafari()) {
    document.addEventListener("touchmove", preventBouncing, {passive: false});
    window.requestAnimationFrame(raf);
}


