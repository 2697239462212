import Utils from './utils';

window.setPageBackground = function(url, backgroundPosition = 'center center') {
    let pageBackground = document.getElementById('page-background');
    let responsiveImage;

    if(Utils.isSafari()) {
        pageBackground = document.getElementById('container');
    }

    if(typeof url == 'string') {
        responsiveImage = url;
    }
    else if(url.huge) {
        const windowWidth = window.innerWidth;

        if(windowWidth > 1200) {
            responsiveImage = url.huge;
        }
        else if (windowWidth > 800) {
            responsiveImage = url.large;
        }
        else if (windowWidth > 400) {
            responsiveImage = url.medium;
        }
        else {
            responsiveImage = url.small;
        }


    }

    pageBackground.style.backgroundImage = `url('${responsiveImage}')`;
    pageBackground.style.backgroundPosition = backgroundPosition;
}

