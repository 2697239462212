import Utils from './utils'

if (Utils.isMobile() && Utils.isSafari()) {
    document.getElementsByTagName('html')[0].classList.add('browser-safari-on-ios');
}

// toggle search in header
var searchToggle = document.getElementById('toggleSearchinHeader');
searchToggle.addEventListener('click', function () {
    var body = document.getElementsByTagName("BODY")[0];
    body.classList.add('js-headerSearchOpen');

    var closeSearch = document.getElementById('closeHeaderSearch');
    closeSearch.addEventListener('click', function () {
        body.classList.remove('js-headerSearchOpen');
        document.getElementById('headerSearchInput').value = '';
    });

});