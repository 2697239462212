// Collection of utilities
var Utils = {
    isMobile: function isMobile() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        );
    },

    isSafari: function isSafari() {
        var ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('safari') != -1 && ua.indexOf('chrome') == -1;
    }
};

export default Utils;