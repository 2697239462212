import ResizeObserver from 'resize-observer-polyfill';

const ro = new ResizeObserver((entries, observer) => {
    for (const entry of entries) {
        const {left, top, width, height} = entry.contentRect;
        const el = entry.target;
        el.classList.add('js-resizeobserver');

        if(el.classList.contains('Facts')) {
            // remove classes
            el.classList.remove('micro');
            el.classList.remove('tiny');
            el.classList.remove('small');
            el.classList.remove('medium');
            el.classList.remove('large');
            el.classList.remove('huge');

            // add class
            if (width <= 400) {
                el.classList.add('small');
            }
            else if (width > 400 && width <= 1100) {
                el.classList.add('large');
            }
            else {
                el.classList.add('huge');
            }
        }
        else if(el.classList.contains('Teaser')) {
            // remove classes
            el.classList.remove('micro');
            el.classList.remove('tiny');
            el.classList.remove('small');
            el.classList.remove('medium');
            el.classList.remove('large');

            // add class
            if (height <= 240) {
                if (width <= 240) {
                    el.classList.add('micro');
                } else {
                    el.classList.add('tiny');
                }
            } else if (height > 240 && height <= 340) {
                el.classList.add('small');
            } else if (height > 340 && height <= 440) {
                el.classList.add('medium');
            } else {
                el.classList.add('large');
            }
        }
    }
});
const teasers = document.querySelectorAll(".Teaser, .Facts");
for (var i = 0; i < teasers.length; ++i) {
    ro.observe(teasers[i]);
}