var allTabs = document.querySelectorAll('.Tabs');
allTabs.forEach(function (tabs) {
    if (typeof tabs !== 'undefined') {
        tabs.addEventListener('click', function () {
            if (this.classList.contains('Tabs--hover')) {
                this.classList.remove('Tabs--hover');
            }
            else {
                this.classList.add('Tabs--hover');
            }
        });

        var tabItems = tabs.querySelectorAll('.Tab');
        tabs.style.minWidth = tabItems[0].clientWidth + 'px';
        tabItems.forEach(function (item, index) {
            // Add active class to first tab item
            if (index === 0) {
                item.classList.add('Tab--active');
            }

            // Add click handler to toggle active class
            item.addEventListener('click', function () {
                tabItems.forEach(function (item) {
                    item.classList.remove('Tab--active');
                });
                this.classList.add('Tab--active');
            });
        });
    }
});